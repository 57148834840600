import {
  ConfirmationDialog,
  DetailColumns,
  DetailPane,
  FormDialog,
  fullDate,
  fullDateTime,
  name,
  useDetail,
  useErrors
} from '@management-ui/core';
import {PictureAsPdf} from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import {Link} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';
import Text from '../../../../components/Text';
import routes from '../../../../routes';
import Status from '../../components/Status';
import LinkReferrerForm from '../../forms/LinkReferrerForm';
import Tabs from './Tabs';
import Wrapper from './Wrapper';
import moment from 'moment/moment';

const Order = ({archive = false}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();

  const {errors, toggleError} = useErrors(useMemo(() => ({
    order: 'Sorry the order could not be accessed'
  }), []));

  const {
    entity: order,
    setEntity: setOrder,
    loadEntity: loadOrder,
    crumbs,
    loading,
    setLoading
  } = useDetail(
    services.order.getOrder,
    archive,
    useCallback((order) => [{title: order.reference}], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => Wrapper.archiveCrumbs, []),
    useCallback((displayError) => toggleError('order', displayError), [toggleError])
  );

  const [showLinkReferrer, setShowLinkReferrer] = useState(false);
  const [showLinkJob, setShowLinkJob] = useState(false);

  const handleLinkReferrer = useCallback(({referrer}) => {
    setShowLinkReferrer(false);
    return new Promise(resolve => {
      if (referrer) {
        setLoading(true);
        services.order.updateOrder(order, {referrer_id: referrer}).then((updated) => {
          setLoading(false);
          setOrder(updated);
          resolve();
        }).catch(() => {
          setLoading(false);
          resolve();
        });
      } else {
        resolve();
      }
    });
  }, [services, setLoading, order, setOrder]);

  const [showDelete, setShowDelete] = useState(false);

  const handleDelete = useCallback(confirmed => {
    setShowDelete(false);
    if (confirmed) {
      setLoading(true);
      services.order.deleteOrder(order).then(() => {
        setLoading(false);
        history.push({
          pathname: reverse(`${routes.orders.index}`),
          search: `?status=${order.status.slug}`
        });
      }).catch(() => setLoading(false));
    }
  }, [services, history, order, setLoading]);

  const handleDownload = useCallback(() => {
    setLoading(true);
    services.order.downloadOrder(order)
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [services, setLoading, order]);

  return (
    <Wrapper title={order ? order.reference : 'Order'} loading={loading} order={order} crumbs={crumbs} errors={errors}>
      {order?.id ? (
        <>
          <DetailColumns columns={[
            <DetailPane
              title="Order Details"
              details={[
                {title: 'Reference', value: order.reference ?? '-'},
                {
                  title: 'Customer', value: name({
                    first_name: order['billing_first_name'],
                    last_name: order['billing_last_name']
                  })
                },
                {
                  title: 'Created',
                  value: fullDateTime(order.created_at),
                },
                {
                  title: 'Placed',
                  value: order.completed_at ? fullDateTime(order.completed_at) : '-'
                },
                {
                  title: 'Referrer',
                  value: order['referrer'] ? order['referrer'].name :
                    <Link onClick={() => setShowLinkReferrer(true)}>Click Here to Link</Link>,
                  route: order['referrer'] ? reverse((order['referrer'].archived ? routes.archive.referrers : routes.referrers).detail, {id: order['referrer'].id}) : null,
                },
                {title: 'Xero ID', value: order['xero_uuid'] ?? '-'},
                {title: 'Status', value: <Status entity={order}/>}
              ]}
              actions={order.status.slug === 'paid' ? [
                {title: 'Download PDF', icon: <PictureAsPdf/>, onClick: handleDownload}
              ] : [
                {title: 'Delete Order', icon: <DeleteIcon/>, onClick: () => setShowDelete(true)}
              ]}
              dialogs={[
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    open={showDelete}
                    title="Delete Order"
                    message="Are you sure you want to delete this order?"
                    onClose={handleDelete}
                  />
                ),
                (props) => (
                  <FormDialog
                    {...props}
                    title="Link a Referrer"
                    open={showLinkReferrer}
                    maxWidth="sm"
                    onClose={() => setShowLinkJob(false)}
                    render={(props) => (
                      <LinkReferrerForm
                        {...props}
                        entity={{}}
                        onSave={handleLinkReferrer}
                      />
                    )}
                  />
                )
              ]}
            />,

            <DetailPane
              title="Notes"
              details={[
                {
                  title: 'Preferred Dates',
                  value: <Text
                    text={(order['preferred_dates'] ?? []).length < 1 ? 'None Provided' : (order['preferred_dates'] ?? []).map((
                      {
                        date,
                        time
                      }
                    ) => `${fullDate(moment(date))} - ${time}`).join('\n')}/>
                },
                {
                  title: 'Comments',
                  value: <Text text={order['comments'] ?? '-'}/>
                },
                {
                  title: 'Source',
                  value: order['source'] ? order['source'] : '-'
                },
                {
                  title: 'Website User',
                  value: order['website_user_id'] ? 'Click Here to View' : 'Not Logged In',
                  link: order['website_user_id'] ? `${process.env.REACT_APP_WEBSITE_USER_LINK}${order['website_user_id']}` : null,
                },
              ]}
            />,

            <DetailPane
              title="Totals"
              details={[
                {
                  title: `Discount${order['discount_title'] ? ` - ${(order['discount_rate'] ?? 0) * 100}% (${order['discount_title']})` : ''}`,
                  value: <Currency amount={order['discount'] / (1 + order['tax_rate'])}/>
                },
                {title: 'Delivery', value: <Currency amount={order['delivery'] / (1 + order['tax_rate'])}/>},
                {title: 'Sub-total', value: <Currency amount={order['sub_total']}/>},
                {title: 'Tax', value: <Currency amount={order['tax']}/>},
                {title: 'Total', value: <Currency amount={order['total']}/>},
              ]}
            />,
          ]}/>

          <Tabs {...{order, setOrder, loadOrder, loading, setLoading, showLinkJob, setShowLinkJob}}/>
        </>
      ) : null}
    </Wrapper>
  );
}

export default Order;
